<template>
  <div class="onboarding_page">
    <h2>Behöver ni flera administratörer?</h2>
    <p>Just nu finns det en grupp som heter "Huvudadministratörer". Det är den grupp som du äger med detta konto.</p>
    <p>Ifall du har andra administratörer som behöver samma rättighet så skapar du en användare under "Användare" och lägger denna i gruppen "Huvudadministratörer".</p>
    <p>Ifall du vill att det ska finnas färre rättigheter eller tillgång till sidor bör du istället skapa en ny grupp och begränsa rättigheterna.</p>
    <p>Efter att du har skapat den nya gruppen så kan du sätta gruppen till användarens grupp.</p>
    <p>En användare kan bara tillhöra en grupp.</p>
    <p></p>
    <p>
      <a href="/ml-users" target="_blank">Till Användare >></a>
    </p>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'onboarding_page6_1',
  props: ['onboarding_data'],
  methods: {
    next_page() {
      this.$emit('update_data', { subcompanies_info: true })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', { subcompanies_info: true })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
