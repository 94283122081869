<template>
  <div class="onboarding_page">
    <h2>Vill ni aktivera betalningslösningar?</h2>
    <p>Kostar era medlemskap pengar? I så fall behöver ni aktivera betallösningar.</p>
    
    <div class="segmented_select_container">
      <memlist-segmented-select-button 
        :parts="activation_options"
        :primary_color="'rgb(0 123 255)'"
        :secondary_color="'#ffffff'"
        @selected="on_activation_selection"
      />
    </div>
    <div v-if="selected_activation === 'ja'" class="payment_solutions">
      <h3>Vilka betalösningar vill ni aktivera?</h3>
      <div class="checkbox_group">
        <memlist-checkbox v-model="solutions.swish" :text="'Swish'" />
        <memlist-checkbox v-model="solutions.stripe" :text="'Stripe (Kreditkort)'" />
        <memlist-checkbox v-model="solutions.faktura" :text="'Faktura'" />
      </div>
    </div>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'onboarding_page4',
  props: ['onboarding_data'],
  data() {
    return {
      activation_options: [
        { value: 'ja', text: 'Ja' },
        { value: 'nej', text: 'Nej' },
        { value: 'kanske_senare', text: 'Kanske senare' }
      ],
      selected_activation: '',
      solutions: {
        swish: false,
        stripe: false,
        faktura: false
      }
    }
  },
  methods: {
    on_activation_selection(value) {
      this.selected_activation = value
    },
    next_page() {
      this.$emit('update_data', {
        payment_activation: this.selected_activation,
        payment_solutions: this.selected_activation === 'ja' ? this.solutions : {}
      })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', {
        payment_activation: this.selected_activation,
        payment_solutions: this.selected_activation === 'ja' ? this.solutions : {}
      })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

.checkbox_group {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.segmented_select_container {
  margin: 20px 0;
}

</style>
