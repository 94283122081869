<template>
  <div class="onboarding_page">
    <h2>Så hittar ni medlemmar som har registrerat sig</h2>

    <p>Samtliga medlemmar hittar ni under "Medlemmar".</p>
    <p>Om ni vill göra en finare sökning baserat på ett urval med variabler går ni till "Avancerat sök".</p>
    <p>Om du vet namnet, personnummret, telefonnummret, medlemsnummret eller e-posten till personen kan du söka på denna direkt längst uppe på sidan i sökfältet.</p>

    <p>
      Gå till
      <a href="/ml-members-control" target="_blank">Medlemmar</a> eller
      <a href="/ml-advanced-search" target="_blank">Avancerad sök</a>
    </p>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onboarding_page8',
  props: ['onboarding_data'],
  methods: {
    next_page() {
      this.$emit('update_data', { members_info: true })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', { members_info: true })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
