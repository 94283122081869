<template>
  <div class="onboarding_page">
    <h2>Grattis! Nu är du redo att börja använda Memlist!</h2>
    <p>Tryck på knappen nedan för att spara all information och komma igång.</p>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="finish_onboarding"
      >
        Klar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onboarding_page10',
  props: ['onboarding_data'],
  methods: {
    finish_onboarding() {
      this.$emit('update_data', { onboarding_complete: true })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', { onboarding_complete: false })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
