<template>
  <div class="onboarding_page">
    <h2>Ange vilka krav ni har på era medlemmar</h2>
    <p>Vilka fält är det som medlemmarna måste fylla i om sig själva för att kunna bli giltig medlem hos er?</p>
    <div class="checkbox_group">
      <memlist-checkbox
        v-model="requirements.swenskt_personnummer"
        :text="'Svenskt Personnummer'"
      />
      <memlist-checkbox
        v-model="requirements.fodelsedag"
        :text="'Födelsedag'"
      />
      <memlist-checkbox
        v-model="requirements.fullstandigt_namn"
        :text="'Fullständigt namn'"
      />
      <memlist-checkbox
        v-model="requirements.email"
        :text="'E-post'"
      />
      <memlist-checkbox
        v-model="requirements.telefon"
        :text="'Telefon'"
      />
      <memlist-checkbox
        v-model="requirements.adress"
        :text="'Adress'"
      />
      <memlist-checkbox
        v-model="requirements.faktura_adress"
        :text="'Faktura adress'"
      />
    </div>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'onboarding_page2',
  props: ['onboarding_data'],
  data() {
    return {
      requirements: {
        swenskt_personnummer: false,
        fodelsedag: false,
        fullstandigt_namn: false,
        email: false,
        telefon: false,
        adress: false,
        faktura_adress: false
      }
    }
  },
  methods: {
    next_page() {
      this.$emit('update_data', { membership_requirements: this.requirements })
      this.$emit('next_page');
    },
    prev_page() {
      this.$emit('update_data', { membership_requirements: this.requirements })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";


.checkbox_group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}


</style>
