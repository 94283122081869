<template>
  <div class="onboarding_page">
    <h2>Om ni vill skapa evenemang</h2>
    <p>Tänk på att ifall ni vill ge möjligheten att ta betalt för biljetter så behöver ni ställa in betalningslösningar.</p>
    <p>
      <a href="/ml-events" target="_blank">Till evenemang >></a>
    </p>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onboarding_page7',
  props: ['onboarding_data'],
  methods: {
    next_page() {
      this.$emit('update_data', { events_info: true })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', { events_info: true })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
