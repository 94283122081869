<template>
  <div class="onboarding_page">
    <h2>Kan era medlemmar vara minderåriga?</h2>
    <p>Ifall ni har medlemmar som är under 18 år måste ni svara Ja på denna.</p>
    <div class="segmented_select_container">
      <memlist-segmented-select-button 
        :parts="minor_options"
        :primary_color="'rgb(0 123 255)'"
        :secondary_color="'#ffffff'"
        @selected="on_selection"
      />
      
    </div>
    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'onboarding_page3',
  props: ['onboarding_data'],
  data() {
    return {
      minor_options: [
        { value: 'ja', text: 'Ja' },
        { value: 'nej', text: 'Nej' },
        { value: 'vet_ej', text: 'Vet ej' }
      ],
      selected_option: ''
    }
  },
  methods: {
    on_selection(value) {
      this.selected_option = value
    },
    next_page() {
      this.$emit('update_data', { minor_possible: this.selected_option })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', { minor_possible: this.selected_option })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

.segmented_select_container {
  margin: 20px 0;
}

</style>
