<template>
  <div class="onboarding_page">
    <div class="page_content">
      <h2>😊 Välkommen till Memlist Onboarding</h2>
      <p>Denna wizard kommer att hjälpa dig att komma igång med Memlist.</p>
    </div>
    
    <div class="wizard_actions">
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'onboarding_page1',
  props: ['onboarding_data'],
  methods: {
    next_page() {
      // No additional data on this page.
      this.$emit('update_data', { welcome_shown: true })
      this.$emit('next_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
