<template>
  <div class="onboarding_page">
    <h2>Vill ni använda er egna webbdomän för att skicka e-post?</h2>
    <p>En webbdomän är en egen .se eller .com domän t ex</p>
    <p>Ifall ni inte äger en egen domän kan ni skippa detta</p>
    <div class="segmented_select_container">
      
      <memlist-segmented-select-button 
        :parts="email_domain_options"
        :primary_color="'rgb(0 123 255)'"
        :secondary_color="'#ffffff'"
        @selected="on_email_domain_selection"
      />
    </div>
    <div v-if="selected_email_domain === 'ja'" class="custom_email">
      <memlist-text-input
        :title="'E-post att skicka från'"
        v-model="custom_email"
        placeholder="Ange e-post att skicka från"
      />
    </div>


    <div class="wizard_actions">
      <button
        class="btn_prev"
        @click="prev_page"
      >
        Föregående
      </button>
      <button
        class="btn_next"
        @click="next_page"
      >
        Nästa
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'onboarding_page5',
  props: ['onboarding_data'],
  data() {
    return {
      email_domain_options: [
        { value: 'ja', text: 'Ja' },
        { value: 'nej', text: 'Nej' },
        { value: 'kanske_senare', text: 'Kanske senare' }
      ],
      selected_email_domain: '',
      custom_email: ''
    }
  },
  methods: {
    on_email_domain_selection(value) {
      this.selected_email_domain = value
    },
    next_page() {
      this.$emit('update_data', {
        custom_email_domain: this.selected_email_domain,
        email_from: this.selected_email_domain === 'ja' ? this.custom_email : ''
      })
      this.$emit('next_page')
    },
    prev_page() {
      this.$emit('update_data', {
        custom_email_domain: this.selected_email_domain,
        email_from: this.selected_email_domain === 'ja' ? this.custom_email : ''
      })
      this.$emit('prev_page')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

.segmented_select_container {
  margin: 20px 0;
}

.custom_email {
  margin-top: 20px;
  display: block;
  width: 100%;
}


</style>
