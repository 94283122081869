<template>
  <div class="onboarding_wizard">
    <!-- Wizard Navigation: show step indicators -->
    <div class="wizard_nav">
      <div
        v-for="(page, index) in pages"
        :key="index"
        class="wizard_step"
        :class="{ active: current_page_index === index }"
      >
        <span>{{ index + 1 }}</span>
      </div>
    </div>

    <div class="wizard-page">

      <!-- Dynamic component for the current page -->
      <component
        :is="current_page_component"
        :onboarding_data="onboarding_data"
        @update_data="update_data"
        @next_page="next_page"
        @prev_page="prev_page"
      />

    </div>

  </div>
</template>

<script>
import axios from 'axios'

// Import all step components
import OnboardingPage1 from './pages/OnboardingPage1.vue'
import OnboardingPage2 from './pages/OnboardingPage2.vue'
import OnboardingPage3 from './pages/OnboardingPage3.vue'
import OnboardingPage4 from './pages/OnboardingPage4.vue'
import OnboardingPage5 from './pages/OnboardingPage5.vue'
import OnboardingPage6 from './pages/OnboardingPage6.vue'
import OnboardingPage6_1 from './pages/OnboardingPage6_1.vue'
import OnboardingPage6_2 from './pages/OnboardingPage6_2.vue'
import OnboardingPage7 from './pages/OnboardingPage7.vue'
import OnboardingPage8 from './pages/OnboardingPage8.vue'
import OnboardingPage9 from './pages/OnboardingPage9.vue'
import OnboardingPage10 from './pages/OnboardingPage10.vue'

export default {
  name: 'onboarding_wizard',
  components: {
    OnboardingPage1,
    OnboardingPage2,
    OnboardingPage3,
    OnboardingPage4,
    OnboardingPage5,
    OnboardingPage6,
    OnboardingPage6_1,
    OnboardingPage6_2,
    OnboardingPage7,
    OnboardingPage8,
    OnboardingPage9,
    OnboardingPage10
  },
  data() {
    return {
      current_page_index: 0,
      // Store imported components directly in the pages array.
      pages: [
        OnboardingPage1,
        OnboardingPage2,
        OnboardingPage3,
        OnboardingPage4,
        OnboardingPage5,
        OnboardingPage6,
        OnboardingPage6_1,
        OnboardingPage6_2,
        
        OnboardingPage7,
        OnboardingPage8,
        OnboardingPage9,
        OnboardingPage10
      ],
      // The cumulative onboarding data from all steps.
      onboarding_data: {}
    }
  },
  computed: {
    current_page_component() {
      return this.pages[this.current_page_index]
    }
  },
  methods: {
    update_data(page_data) {
      // Merge incoming data with existing onboarding_data.
      this.onboarding_data = { ...this.onboarding_data, ...page_data }
    },
    next_page() {
      this.current_page_index++
    },
    prev_page() {
      this.current_page_index--
    },
    submit_onboarding() {
      // Submit the onboarding_data via PUT /customer.
      axios
        .put('/customer', { onboarding_data: this.onboarding_data })
        .then(response => {
          alert('Onboarding complete!')
        })
        .catch(error => {
          console.error('Error submitting onboarding:', error)
          alert('Fel vid sparande av onboarding-data.')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_wizard.scss";

</style>
